import { useEffect, useState } from "react";
import Link from "next/link";
import BestSeller from "./BestSeller";
import ProductCategory from "./ProductCategory";
import { Paginate } from "../../../models/model";
import Product, { IProduct } from "../../../models/product.model";
import { IUserBookmark } from "../../../models/userBookmark.model";
import FlashSaleSection from "@Pages/shop/components/FlashSaleSection";
import { FlashSale } from "models/flashSale.model";

// const BestSeller = dynamic(() => import("./BestSeller"), {
//    // ssr: true,
//    loading: () => <div style={{ height: "312px" }} />
// });

// const ProductCategory = dynamic(() => import("./ProductCategory"), {
//    ssr: false,
//    loading: () => <div style={{ height: "270px" }} />
// });

interface IProps {
   isMobile: boolean;
   products: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   productsPropose: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   flashSaleWithProduct: {
      ["paginate"]: Paginate<Product, IProduct>;
      ["flashSales"]: FlashSale;
   };
}
export default function ECommerceOverall(props: IProps) {
   const [state, setCurrentState] = useState<{
      isActiveFlashSale?: boolean;
   }>({});
   const [userBookmarks, setUserBookmarks] = useState([...(props.products.userBookmarks ?? []), ...(props.productsPropose.userBookmarks ?? [])]);

   function setState(newState) {
      setCurrentState((prev) => ({
         ...prev,
         ...newState,
      }));
   }

   useEffect(() => {
      setUserBookmarks([...(props.products.userBookmarks ?? []), ...(props.productsPropose.userBookmarks ?? [])]);
   }, [props.products, props.productsPropose]);

   useEffect(() => {
      setState({
         isActiveFlashSale: isStartFlashSale(props.flashSaleWithProduct.flashSales),
      });
   }, [props.flashSaleWithProduct.flashSales]);

   const isStartFlashSale = (flashSales) => {
      if (!flashSales) return;
      const currentTime = new Date().getTime();
      const startFlashsaleNum = new Date(flashSales?.info?.startDateTime || 0).getTime();
      const endFlashsaleNum = new Date(flashSales?.info?.endDateTime || 0).getTime();
      const isStartFlashSale = currentTime >= startFlashsaleNum && currentTime <= endFlashsaleNum;
      return isStartFlashSale;
   };

   const handleEndCountdownFlashSales = () => {
      setState({
         isActiveFlashSale: isStartFlashSale(props.flashSaleWithProduct.flashSales),
      });
   };

   const handleChangeUserBookmark = (action: "create" | "delete", userBookmark: IUserBookmark) => {
      if (action === "create") {
         setUserBookmarks([...userBookmarks, userBookmark]);
      } else {
         const newUserBookmarks = userBookmarks.filter((bookmark) => bookmark._id !== userBookmark._id);
         setUserBookmarks(newUserBookmarks);
      }
   };

   return (
      <div className="block">
         <div className="block-header">
            <Link title="Siêu thị nội thất" href="/shop">
               <h2 style={{ margin: 0, fontSize: "unset" }}>
                  <p className="block-header__title">Shop nội thất </p>
               </h2>
            </Link>
            <p className="block-header__description">Thỏa sức lựa chọn sản phẩm nội thất bạn yêu thích</p>
         </div>
         <ProductCategory />
         {state.isActiveFlashSale === undefined ? (
            <div className="flash-sale-init"></div>
         ) : (
            state.isActiveFlashSale && props.flashSaleWithProduct?.flashSales && (
               <FlashSaleSection
                  isMobile={props.isMobile}
                  paginateFlashSale={props.flashSaleWithProduct.paginate}
                  flashSale={props.flashSaleWithProduct?.flashSales}
                  onEndCountdownFlashSales={handleEndCountdownFlashSales}
               />
            )
         )}
         <BestSeller products={props.products} userBookmarks={userBookmarks} handleChangeUserBookmark={handleChangeUserBookmark} />
         <BestSeller title={"propose"} products={props.productsPropose} userBookmarks={userBookmarks} handleChangeUserBookmark={handleChangeUserBookmark} />
      </div>
   );
}
