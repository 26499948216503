import Image from "next/image";
import styles from "./Calculator.module.scss";
import Slider from "react-slick";
import Link from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";

interface IProps {}

const DATA = [
   {
      type: "Căn hộ",
      src: "/assets/img/icon/can-ho.svg",
      url: "can-ho",
      des: "Chi phí tổng thể căn hộ",
      desMobile: "Chi phí tổng thể căn hộ"
   },
   {
      type: "Nhà ở",
      src: "/assets/img/icon/nha-o.svg",
      url: "nha-o",
      des: "Báo giá trọn gói nhà ở",
      desMobile: "Báo giá trọn gói nhà ở"
   },
   {
      type: "Bếp",
      src: "/assets/img/icon/bep.svg",
      url: "bep",
      des: "Báo giá bếp chính xác",
      desMobile: "Báo giá bếp chính xác"
   },
   {
      type: "Tủ quần áo",
      src: "/assets/img/icon/tu-quan-ao.svg",
      url: "tu-quan-ao",
      des: "Giá trọn gói tủ quần áo",
      desMobile: "Giá trọn gói tủ quần áo"
   }
];

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 4,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
   responsive: [
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1
         }
      },
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1
         }
      },
      {
         breakpoint: 375,
         settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1
         }
      }
   ]
};

export default function Calculator(props: IProps) {
   const router = useRouter();
   return (
      <>
         <div className="article interior-design">
            <div className="block-sub d-flex justify-content-between align-items-center">
               <h2 style={{ maxWidth: "72%" }} title="Quy trình làm nội thất cùng Space T" className="block-sub-title">
                  Công cụ tính chi phí thi công
               </h2>
               {router.query.isApp === "true" ? null : (
                  <Link title="Báo giá" href="/bao-gia" className="link-more link">
                     Xem tất cả &#62;
                  </Link>
               )}
            </div>
            <div className={classNames("slider d-none d-lg-block", styles["grid"])}>
               <Slider {...settingsSlider}>
                  {DATA.map((item, index) => {
                     return (
                        <div key={index} className={styles["option-calculator"]}>
                           <Link style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}} href={`/bao-gia/${item.url}`}>
                              <Image width={104} height={104} src={item.src} alt={String(item.des)} />
                              <hr className={styles["line-break"]} />
                              <div className={styles["text"]}>
                                 <strong className={styles["strong"]}>{item.type}</strong>
                                 <span className={styles["des"]}>{item.des}</span>
                              </div>
                           </Link>
                        </div>
                     );
                  })}
               </Slider>
            </div>
            <div className={classNames(styles["mobile-row"], "row g-2 d-flex d-lg-none")}>
               {DATA.map((item, index) => {
                  return (
                     <div key={index} className="col-6">
                        <div onClick={() => router.push(`/bao-gia/${item.url}`)} className={styles["option-calculator"]}>
                           <Image className={styles["img"]} width={32} height={32} src={item.src} alt="SpaceT" />
                           <div className={styles["text"]}>
                              <strong className={styles["strong"]}>{item.type}</strong>
                              <span className={styles["des"]}>{item.desMobile}</span>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
         </div>
      </>
   );
}
