import Image from "next/image";
import Link from "next/link";
import React from "react";
const pathFromS3Server = "https://d185fpybxuxxq6.cloudfront.net/img";

interface IState {}

interface IProps {}

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 2.5,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
};

export default class ProductCategory extends React.Component<IProps, IState> {
   private readonly _filterCategory = [
      {
         img: "/assets/img/icon/filter-shop/phong-khach.png",
         url: "/phong-khach",
         title: "Phòng khách",
      },
      {
         img: "/assets/img/icon/filter-shop/phong-ngu.png",
         url: "/phong-ngu",
         title: "Phòng ngủ",
      },
      {
         img: "/assets/img/icon/filter-shop/phong-an.png",
         url: "/phong-an-bep",
         title: "Phòng ăn",
      },
      {
         img: "/assets/img/icon/filter-shop/nha-bep.png",
         url: "/phong-an-bep",
         title: "Nhà bếp",
      },
      {
         img: "/assets/img/icon/filter-shop/phong-lam-viec.png",
         url: "/phong-lam-viec",
         title: "Phòng làm việc",
      },
      {
         img: "/assets/img/icon/filter-shop/phong-tam.png",
         url: "/phong-tam",
         title: "Phòng tắm",
      },
      {
         img: "/assets/img/icon/filter-shop/phong-cho-be.png",
         url: "/phong-cho-be",
         title: "Phòng cho bé",
      },
      {
         img: "/assets/img/icon/filter-shop/cua-ra-vao.png",
         url: "/cua-ra-vao",
         title: "Cửa ra vào",
      },
   ];

   private readonly _filterProduct = [
      {
         img: "/assets/img/icon/filter-shop/category/furniture.png",
         url: "/noi-that",
         title: "Nội thất",
      },
      {
         img: "/assets/img/icon/filter-shop/category/decoration.png",
         url: "/do-trang-tri",
         title: "Đồ trang trí",
      },
      {
         img: "/assets/img/icon/filter-shop/category/household-appliances.png",
         url: "/do-gia-dung",
         title: "Đồ gia dụng ",
      },
      {
         img: "/assets/img/icon/filter-shop/category/storage.png",
         url: "/luu-tru",
         title: "Lưu trữ",
      },
      {
         img: "/assets/img/icon/filter-shop/category/pillow.png",
         url: "/chan-ga-goi-dem",
         title: "Chăn ga gối đệm",
         space: true,
      },
      {
         img: "/assets/img/icon/filter-shop/category/lamp.png",
         url: "/den",
         title: "Đèn",
      },
      {
         img: "/assets/img/icon/filter-shop/category/carpet.png",
         url: "/tham",
         title: "Thảm",
      },
      {
         img: "/assets/img/icon/filter-shop/category/all.png",
         url: "/",
         title: "Tất cả",
      },
   ];

   constructor(props: any) {
      super(props);
      this.state = {};
   }

   render() {
      return (
         <>
            <section className="product-category">
               <div className="block-sub d-flex justify-content-between align-items-center ">
                  <Link title="Shop" href="/shop" className="block-sub-title">
                     Lọc theo sản phẩm
                  </Link>
               </div>
               <div className="product-category-item">
                  <div className="home-catalog-list">
                     {this._filterProduct.map((item, index) => {
                        return (
                           <Link id="category-item" href={`/shop${item.url ?? ""}`} className="text-decoration-none catalog-item " key={index}>
                              <div className="img">
                                 <Image width={65} height={65} src={item.img} alt={item.title} />
                                 <div className="cover"></div>
                              </div>
                              <h3 className="catalog-item__name">{item.title}</h3>
                           </Link>
                        );
                     })}
                  </div>
               </div>
            </section>
            <section className="product-category">
               <div className="block-sub d-flex justify-content-between align-items-center ">
                  <Link title="Shop" href="/shop" className="block-sub-title">
                     Lọc theo danh mục
                  </Link>
               </div>
               <div className="product-category-item">
                  <div className="home-catalog-list">
                     {this._filterCategory.map((item, index) => {
                        return (
                           <Link
                              id="category-item"
                              title={item.title}
                              href={`/shop${item.url ?? ""}`}
                              className="text-decoration-none catalog-item "
                              key={index}
                              prefetch={false}
                           >
                              <div className="img">
                                 <Image width={65} height={65} src={item.img} alt={item.title} />
                                 <div className="cover"></div>
                              </div>
                              <h3 className="catalog-item__name">{item.title}</h3>
                           </Link>
                        );
                     })}
                  </div>
               </div>
            </section>
         </>
      );
   }
}
