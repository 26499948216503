import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import Slider from "react-slick";

interface ICustomerReview {
   imgSrcBefore: string;
   name: string;
   rating: number;
   review: any;
   des?: string;
}
interface IState {
   customerReview: ICustomerReview[];
   activeItem: number;
}

export default class Review extends React.Component<any, IState> {
   constructor(props: any) {
      super(props);
      this.state = {
         activeItem: 0,
         customerReview: [
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/service-connect/user_feedback_1.webp",
               name: "Căn hộ tại Cách Mạng Tháng 8",
               rating: 4.7,
               review:<span>
               - Loại công trình: <span className="bold">Căn hộ</span> <br/>
               - Phong cách: <span className="bold">Scandinavian</span><br/>
               - Khu vực: <span className="bold">Cách mạng Tháng 8, HCM</span><br/>
               - Chủ đầu tư: <span className="bold">Mr. Sơn</span><br/>
               </span>
               ,
               // des: "Căn hộ New City, Quận 2, TP.HCM."
            },
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/service-connect/user_feedback_2.webp",
               name: "Chung cư An Phú",
               rating: 4.2,
               review: <div>
                  - Loại công trình: <span className="bold">Chung cư An Phú </span><br/>
                  - Phong cách: <span className="bold">Neo Classic</span><br/>
                  - Chủ đầu tư: <span className="bold">Ms. Trân</span><br/>
               </div>,
               // des: "Căn hộ Opal Saigon Pearl, Bình Thạnh, TP.HCM."
            },
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/service-connect/user_feedback_3.webp",
               name: "Cải tạo nội thất Nhà Phố",
               rating: 4.8,
               review: <div>
                  - Loại công trình: <span className="bold">Nhà phố </span><br/>
                  - Phong cách: <span className="bold">Modern (Hiện đại)</span><br/>
                  - Khu vực: <span className="bold">Quận 12</span><br/>
                  - Chủ đầu tư: <span className="bold">Mr. Thọ</span><br/>
               </div>
            },

            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/service-connect/user_feedback_4.webp",
               name: "Nhà phố Lãnh Bình Thăng",
               rating: 4.2,
               review: <div>
                  - Loại công trình: <span className="bold">Nhà phố </span><br/>
                  - Phong cách: <span className="bold">Modern (Hiện đại)</span><br/>
                  - Khu vực: <span className="bold">Lãnh Bình Thăng, Quận 11</span><br/>
               </div>
            },
            
         ]
      };
   }
   handleStepChange = (item: number) => {
      this.setState({ activeItem: item });
   };
   render() {
      const settings = {
         infinite: false,
         autoplay: false,
         cssEase: "linear",
         slidesToShow: 1,
         slidesToScroll: 1,
         dots: true,
         nextArrow: <ArrowForwardIos />,
         prevArrow: <ArrowBackIosNew />
      };
      return (
         <section className="review">
            <div className="block-sub d-flex justify-content-between align-items-center">
               <h2 className="block-sub-title">Chia sẻ từ khách hàng</h2>
            </div>
            <div className="slider-wrap">
               <Slider {...settings}>
                  {this.state.customerReview.map((item, i) => {
                     return (
                        <div className="slider-item" key={i}>
                           <div className="row g-0">
                              <div className="img position-relative col-12 col-md-6">
                                 <img src={item.imgSrcBefore} alt="Phản hồi từ khách hàng" style={{ width: "100%" }} />
                                 <div className="cover"></div>
                              </div>
                              <div className="review-detail col-12 col-md-6">
                                 <div className="review-detail__title">{item.name}</div>
                                 <div className="review-detail__rating">
                                    <img src="/assets/img/cover/ful-star.svg" alt="#" />
                                    <span>{item.rating}/5</span>
                                 </div>
                                 <div className="review-detail__des">
                                    {/* <sup>
                                       <img src="/assets/img/icon/left-quote.svg" alt="" />
                                    </sup> */}
                                    &nbsp;
                                    {item.review}
                                    &nbsp;
                                    {/* <sup>
                                       <img src="/assets/img/icon/left-quote.svg" alt="" style={{ transform: "rotate(180deg)" }} />
                                    </sup> */}
                                 </div>
                                 <p className="review-detail__sub-des">{item.des}</p>
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </Slider>
            </div>
         </section>
      );
      // return (
      //     <section className="slider-review bg-pink mb-3 mb-lg-4">
      //         <div className="container">
      //             <h2 className="title">Phản hồi từ khách hàng</h2>
      //             <div className="slider-wrap">
      //                 <Slider {...settings}>
      //                     {
      //                         this.state.customerReview.map((item, i) => {
      //                             return (
      //                                 <div className="slider-item" key={i}>
      //                                     <div className="row gx-0">
      //                                         <div className="col-md-6 col-12 position-relative">
      //                                             <Image width={550} height={330} src={item.imgSrc} alt="Phản hồi từ khách hàng" />
      //                                             <div className="cover"></div>
      //                                         </div>
      //                                         <div className="col-md-6 col-12">
      //                                             <div className="slider-item-customer">
      //                                                 <h3 className=" title">{item.name}</h3>
      //                                                 <div className="rating"><img src="/assets/img/cover/full-star.webp" alt="#" /><span>{item.rating}/5</span></div>
      //                                                 <p className="des"><sup><img src="/assets/img/icon/left-quote.svg" alt="Space T" /></sup> {item.review} <sup><img src="/assets/img/icon/left-quote.svg" alt="Space T" style={{ transform: "rotate(180deg)" }} /></sup></p>
      //                                                 <p className="sub-des">{item.des}</p>
      //                                             </div>
      //                                         </div>
      //                                     </div>
      //                                 </div>
      //                             )
      //                         })
      //                     }
      //                 </Slider>
      //             </div>
      //         </div>
      //     </section>

      // )
   }
}
