import { Model } from "./model";
import { cloudFrontHost } from "./__variables";

export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440",
}

export interface IMediaMeta {
   altText: string;
   description: string;
   title: string;
}

export interface IMedia extends Model {
   ["container"]: string
   ["domain"]: string
   ["originalName"]: string
   ["protocol"]: string
   ["size"]?: number
   ["width"]?: number
   ["height"]?: number
   ["extension"]: string
   ["fileType"]: string
   ["position"]?: number
   ["description"]?: string;

   // #[custom]
   ["$url"]?: string
   // ["$urlXSResizeMax"]?: string
   // ["$urlSMResizeMax"]?: string
   // ["$urlMDResizeMax"]?: string
   // ["$urlLGResizeMax"]?: string
}

export default class Media<T extends IMedia = IMedia> extends Model {
   ["container"]: string
   ["domain"]: string
   ["originalName"]: string
   ["protocol"]: string
   ["size"]?: number
   ["width"]?: number
   ["height"]?: number
   ["extension"]: string
   ["fileType"]: string
   ["position"]?: number
   ["meta"]?: IMediaMeta

   // #[custom]
   ["$url"]?: string
   // ["$urlXSResizeMax"]?: string
   // ["$urlSMResizeMax"]?: string
   // ["$urlMDResizeMax"]?: string
   // ["$urlLGResizeMax"]?: string

   constructor(data?: T) {
      super(data);
      Object.assign(this, data);
      if (!data) return;

      const filePath = `${this.originalName}`
      if (filePath && this.protocol && this.domain && this.container) {
         this.$url = `${this.protocol}://${this.domain}/${this.container}/${filePath}`;
         // this.$urlXSResizeMax = `${cloudFrontHost}/image-resized/${ESolutionResizeImage.xs}x${ESolutionResizeImage.xs}_max/${this.container}/${filePath}`;
         // this.$urlSMResizeMax = `${cloudFrontHost}/image-resized/${ESolutionResizeImage.sm}x${ESolutionResizeImage.sm}_max/${this.container}/${filePath}`;
         // this.$urlMDResizeMax = `${cloudFrontHost}/image-resized/${ESolutionResizeImage.md}x${ESolutionResizeImage.md}_max/${this.container}/${filePath}`;
         // this.$urlLGResizeMax = `${cloudFrontHost}/image-resized/${ESolutionResizeImage.lg}x${ESolutionResizeImage.lg}_max/${this.container}/${filePath}`;
      }
   }
}
