import React from "react";
import Link from "next/link";
import Slider from "react-slick";
import { ImageView } from "../../../components/image";
import Keyword, { EType } from "../../../models/keyword.model";
import Image from "next/image";
import { useRouter } from "next/router";

interface IProps {
   keywordGroupsByType?: {
      [type in EType]: Keyword[];
   };
}

export default function ImageBySpaces({ keywordGroupsByType }: IProps) {
   // private readonly listSpaces = ['Phong ngu', 'Phong khach', 'Phong an', 'Phong bep'];
   const router = useRouter();
   return (
      <section className="article interior-design">
         <div className="block-sub d-flex justify-content-between align-items-center">
            <h2 style={{ margin: 0, fontSize: "unset" }}>
               <Link title="Nội thất theo phòng" href="/thu-vien-anh" className="block-sub-title">
                  Nội thất theo phòng
               </Link>
            </h2>
            {router.query.isApp === "true" ? null : (
               <Link title="Thư viện thiết kế" href="/thu-vien-thiet-ke" className="link-more link">
                  Xem tất cả &#62;
               </Link>
            )}
         </div>
         <Slider
            dots={false}
            speed={500}
            slidesToShow={6}
            slidesToScroll={6}
            infinite={false}
            nextArrow={<img width={30} height={30} src="/assets/img/icon/renovation/slider-next-2.svg" alt="icon-arrow" />}
            prevArrow={<img width={30} height={30} src="/assets/img/icon/renovation/slider-back-2.svg" alt="icon-arrow" />}
            responsive={[
               {
                  breakpoint: 768,
                  settings: {
                     slidesToShow: 3.5,
                     slidesToScroll: 3,
                  },
               },
               {
                  breakpoint: 576,
                  settings: {
                     slidesToShow: 2.5,
                     slidesToScroll: 2,
                  },
               },
            ]}
            className={"slider-image-by-space_custom"}
         >
            {keywordGroupsByType?.space?.map((item, i) => {
               return (
                  <div key={item._id} className="slider-item">
                     <div className="article-item">
                        <Link
                           style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}}
                           title={item?.info?.content ?? ""}
                           href={`/thu-vien-anh/${item.info.slug}`}
                           className="text-decoration-none"
                        >
                           <div className="img">
                              <ImageView objectFit="fill" src={item?.relatedInfo?.imageUrl ?? ""} alt="image-by-space" className="type-space-item_image" />
                           </div>
                           <div className="article-item-info">
                              <div className="truncate-line-1 title">
                                 <h3 className="text-center">{item?.info?.content ?? ""}</h3>
                                 {/* <h3 className="text-center" dangerouslySetInnerHTML={{ __html: item?.info?.content ?? '' }} /> */}
                              </div>
                           </div>
                        </Link>
                     </div>
                  </div>
               );
            })}
         </Slider>
      </section>
   );
}
