import React from "react";
import dynamic from "next/dynamic";
import { NextSeoProps } from "next-seo";
import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import Product, { IProduct } from "../models/product.model";
import { _metadata } from "../utils/local-data";
import HomePage from "../models/homepage.model";
import Keyword, { EType } from "../models/keyword.model";
import Brand from "../models/brand.model";
import LayoutContainer from "../components/Container";

import ConstructionOverall from "modules/home/components/Construction";
import ECommerceOverall from "modules/home/components/ECommerce";
import ContentEndPage from "modules/home/components/ContentEndPage";
import ActualWork, { IActualWork } from "models/actualWork.model";
import { Paginate } from "models/model";
import { IUserBookmark } from "models/userBookmark.model";
import { FlashSale } from "models/flashSale.model";
import { GlobalComponentProps } from "models/types";
// import MenuItem from "models/menuItem.model";
import Partner, { IPartner } from "models/partner.model";
import { sharedServerSideLogic } from "utils/sharedServerSideLogic";
import DialogAds from "@Components/DialogAds";
import { getDevices, getUserSecretIp } from "utils/SSR/index.handler";
import Banner, { EBannerDevice, EBannerSection } from "@Models/banner.model";

const CommunityOverall = dynamic(() => import("modules/home/components/Community"), {
   ssr: false,
   loading: () => <div className="feed-height-init" />,
});
const Banners = dynamic(() => import("modules/home/components/Banners"), {
   ssr: true,
   loading: () => <div style={{ height: "400px" }} />,
});

interface IState {}

interface IProps extends GlobalComponentProps, InferGetServerSidePropsType<typeof getServerSideProps> {
   // seo: any;
   // keywordGroupsByType: {
   //    [type in EType]: Keyword[];
   // };
   // dataHomepage: {
   //    actualWorks: Paginate<ActualWork, IActualWork>;
   //    designLibraries: Paginate<ActualWork, IActualWork>;
   //    designLibraryUserBookmarks: IUserBookmark[];
   // };
   // products: {
   //    paginate: Paginate<Product, IProduct>;
   //    userBookmarks: IUserBookmark[];
   // };
   // productsPropose: {
   //    paginate: Paginate<Product, IProduct>;
   //    userBookmarks: IUserBookmark[];
   // };
   // brands: Brand[];
   // flashSaleWithProduct: {
   //    ["paginate"]: Paginate<Product, IProduct>;
   //    ["flashSales"]: FlashSale;
   // };
   // partners: Paginate<Partner, IPartner>;
}

export const URL_ACCEPT_BUTTON = ["/", "/thiet-ke-noi-that", "/thi-cong-noi-that", "/quy-trinh-ket-noi"];
export default class Home extends React.Component<IProps, IState> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }
   componentDidMount(): void {
      window.history.scrollRestoration = "auto";
   }

   render() {
      // const customCTA = URL_ACCEPT_BUTTON.includes(this.props.router.pathname);
      return (
         <>
            <div className="page-content" id="pageContent">
               <main className="home-page">
                  <h1 className="d-none">{_metadata[this.props.router.pathname]?.title}</h1>
                  <Banners data={this.props.banners} />
                  <LayoutContainer className="main-content">
                     {this.props.dataHomepage && (
                        <CommunityOverall {...this.props} dataHomepage={this.props.dataHomepage} keywordGroupsByType={this.props.keywordGroupsByType} />
                     )}
                     <ECommerceOverall
                        isMobile={this.props.isMobile}
                        flashSaleWithProduct={this.props.flashSaleWithProduct}
                        products={this.props.products}
                        productsPropose={this.props.productsPropose}
                     />
                     {this.props.dataHomepage && (
                        <ConstructionOverall {...this.props} dataHomepage={this.props.dataHomepage} keywordGroupsByType={this.props.keywordGroupsByType} />
                     )}
                     <ContentEndPage
                        brands={this.props.brands}
                        menulv2={this.props.menu.levelTwo?.shop?.filter((it) => it.info.slug)}
                        partners={this.props.partners}
                     />
                  </LayoutContainer>
               </main>
            </div>
            <DialogAds />
         </>
      );
   }
}

export const getServerSideProps: GetServerSideProps = async (ctx: GetServerSidePropsContext) => {
   const cookiesToken = ctx.req.cookies.userToken || "";
   const userIp = getUserSecretIp(ctx.req);
   // console.log("userIp", userIp);
   const { isMobile } = getDevices(ctx.req);

   let keywordGroupsByType;
   // let homePage: any = {};
   let actualWorks;
   let designLibraries;
   let products;
   let productsPropose;
   let brands: any;
   const [temp1, temp2, temp3, temp4, temp5, temp6, temp7, temp8, commonProps, banners] = await Promise.all([
      Keyword.getKeywordsByType([
         // EType.color, EType.interiorStyle,
         EType.space,
      ]),
      // HomePage.listHomePage(cookiesToken),
      HomePage.actualWorks(),
      HomePage.designLibraries(),
      Product.paginate({ userIp, limit: 6, groupIds: "san-pham-ban-chay", sort: "desc", cookiesToken }),
      Product.paginate({ userIp, limit: 6, groupIds: "san-pham-de-xuat", sort: "desc", cookiesToken }),
      Brand.paginate({}),
      FlashSale.paginate({ cookiesToken, page: 1, limit: 10 }),
      Partner.getPartnerPaginate({ page: 1, limit: 1000 }),
      sharedServerSideLogic(ctx),
      Banner.getBanners({
         sections: [EBannerSection.homepage],
         devicetype: isMobile ? EBannerDevice.mobileweb : EBannerDevice.web,
      }),
   ]);
   keywordGroupsByType = JSON.parse(JSON.stringify(temp1));
   // homePage = JSON.parse(JSON.stringify(temp2));
   actualWorks = JSON.parse(JSON.stringify(temp2));
   designLibraries = JSON.parse(JSON.stringify(temp3));
   products = JSON.parse(JSON.stringify(temp4));
   productsPropose = JSON.parse(JSON.stringify(temp5));
   brands = JSON.parse(JSON.stringify(temp6));
   const flashSaleWithProduct = JSON.parse(JSON.stringify(temp7));
   const partners = JSON.parse(JSON.stringify(temp8));

   brands?.docs?.forEach((brand) => {
      if (brand.logo) {
         delete brand.logo;
      }
   });
   const title = _metadata[ctx?.resolvedUrl]?.title ?? "";
   const description = _metadata[ctx?.resolvedUrl]?.description ?? "";
   const seo: NextSeoProps = {
      title: commonProps?.metaData?.title ? commonProps?.metaData?.title : title,
      description: commonProps?.metaData?.description ? commonProps?.metaData?.description : description,
      openGraph: {
         title: commonProps?.metaData?.title ? commonProps?.metaData?.title : title,
         description: commonProps?.metaData?.description ? commonProps?.metaData?.description : description,
         type: "website",
         images: [
            {
               url: commonProps?.metaData?.thumbnail ? commonProps?.metaData?.thumbnail : _metadata[ctx?.resolvedUrl?.split("?")[0]]?.image || "",
            },
         ],
      },
   };
   return {
      props: {
         seo,
         banners,
         keywordGroupsByType: keywordGroupsByType,
         dataHomepage: {
            actualWorks,
            designLibraries,
         },
         products: products,
         productsPropose: productsPropose,
         brands: brands?.docs,
         flashSaleWithProduct,
         partners,
      },
   };
};
