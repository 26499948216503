import React, { useEffect, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { Skeleton } from "@mui/material";
import { ImageView } from "../../../components/image";
import { Paginate } from "../../../models/model";
import ActualWork, { IActualWork } from "../../../models/actualWork.model";
// import BookmarkPost from "../../../components/bookmark/BookmarkPost";
import { EUserBookmarkTypes, IUserBookmark } from "../../../models/userBookmark.model";

const BookmarkPost = dynamic(() => import("../../../components/bookmark/BookmarkPost"), {
   ssr: false,
   loading: () => <div style={{ height: "270px" }} />
});
interface IProps {
   actualWorks?: Paginate<ActualWork, IActualWork>;
   designLibrary?: Paginate<ActualWork, IActualWork>;
   userBookmarks?: IUserBookmark[];
}

export default function DesignLibrary({ userBookmarks, actualWorks, designLibrary }: IProps) {
   const [userBookmarksState, setUserBookmarks] = useState<IUserBookmark[] | undefined>(userBookmarks);

   useEffect(() => {
      setUserBookmarks(userBookmarks);
   }, [userBookmarks]);

   return (
      <section className="article">
         <div className="block-sub d-flex justify-content-between align-items-center">
            <h2 style={{ margin: 0, fontSize: "unset" }}>
               <Link title="Mẫu thiết kế" href="/thu-vien-thiet-ke" className="block-sub-title">
                  Dự án hoàn thiện
               </Link>
            </h2>
            <Link title="Mẫu thiết kế" href="/thu-vien-thiet-ke" className="link-more link">
               Xem tất cả &#62;
            </Link>
         </div>
         <div className="row article-list">
            {!actualWorks ? (
               [...Array(4)].map((e, i) => {
                  return (
                     <div className="col-6 col-md-3" key={i}>
                        <div className="article-item">
                           <div className="img">
                              <Skeleton animation="wave" width="100%" height="100%" />
                           </div>
                           <div className="title">
                              <Skeleton animation="wave" width="100%" height={20} />
                           </div>
                        </div>
                     </div>
                  );
               })
            ) : (
               <>
                  {designLibrary?.docs?.slice(0, 2).map((doc, i) => {
                     const bookmark = userBookmarksState?.find((bookmark: any) => bookmark.info.openId === doc._id);
                     return (
                        <div className="col-6 col-md-3" key={i}>
                           <div className="article-item">
                              <div className="img">
                                 <Link title={doc.info.title ?? ""} href={`/thu-vien-thiet-ke/${doc.info.slug ?? ""}`} className="text-decoration-none">
                                    <ImageView
                                       objectFit="cover"
                                       src={doc.__media?.$url3And4ColResizeMax ?? ""}
                                       alt={doc.info.title ?? ""}
                                       title={doc?.info?.title ?? ""}
                                    />
                                 </Link>
                                 <BookmarkPost id={doc._id} type={EUserBookmarkTypes.construction} defaultValue={bookmark} />
                              </div>
                              <Link title={doc.info.title ?? ""} href={`/thu-vien-thiet-ke/${doc.info.slug ?? ""}`} className="text-decoration-none">
                                 <h3 className="truncate-line-1 title">{doc.info.title}</h3>
                              </Link>
                           </div>
                        </div>
                     );
                  })}
                  {actualWorks?.docs?.slice(0, 2).map((doc, i) => {
                     const bookmark = userBookmarksState?.find((bookmark: any) => bookmark.info.openId === doc._id);
                     return (
                        <div className="col-6 col-md-3" key={i}>
                           <div className="article-item">
                              <div className="img">
                                 <Link title={doc.info.title ?? ""} href={`/cong-trinh-thuc-te/${doc.info.slug ?? ""}`} className="text-decoration-none">
                                    <ImageView
                                       objectFit="cover"
                                       src={doc.__media?.$url3And4ColResizeMax ?? ""}
                                       // sizes="30vw"
                                       alt={doc.info.title ?? ""}
                                       title={doc?.info?.title ?? ""}
                                    />
                                 </Link>
                                 <BookmarkPost id={doc._id} type={EUserBookmarkTypes.construction} defaultValue={bookmark} />
                              </div>
                              <Link title={doc.info.title ?? ""} href={`/thu-vien-thiet-ke/${doc.info.slug ?? ""}`} className="text-decoration-none">
                                 <h3 className="truncate-line-1 title">{doc.info.title}</h3>
                              </Link>
                           </div>
                        </div>
                     );
                  })}
               </>
            )}
         </div>
      </section>
   );
}
