import Link from "next/link";
// import dynamic from "next/dynamic";
import { Paginate } from "../../../models/model";
// import { IPartner } from "../../../models/partner.model";
import { IUserBookmark } from "../../../models/userBookmark.model";
import ActualWork, { IActualWork } from "../../../models/actualWork.model";

// import Partners from "./Partner";
// import Procedure from "./Procedure";
import Review from "./Review";
import ImageBySpaces from "./ImageBySpace";
import DesignLibrary from "./DesignLibrary";
import Calculator from "pages/quy-trinh-ket-noi/components/calculator/Calculator";

// const Calculator = dynamic(() => import('../../quy-trinh-ket-noi/components/calculator/Calculator'), {
//    ssr: false,
//    loading: () => <div style={{ height: '250px' }} />,
//  })

//  const Review = dynamic(() => import('./Review'), {
//    ssr: false,
//    loading: () => <div style={{ height: '430px' }} />,
//  })
//  const ImageBySpaces = dynamic(() => import('./ImageBySpace'), {
//    // ssr: true,
//    loading: () => <div style={{ height: '250px' }} />,
//  })
//  const DesignLibrary = dynamic(() => import('./DesignLibrary'), {
//    // ssr: true,
//    loading: () => <div style={{ height: '250px' }} />,
//  })

interface IProps {
   dataHomepage: {
      actualWorks: Paginate<ActualWork, IActualWork>;
      designLibraries: Paginate<ActualWork, IActualWork>;
      designLibraryUserBookmarks: IUserBookmark[];
   };
   keywordGroupsByType?: any;
}
export default function ConstructionOverall(props: IProps) {
   return (
      <>
         <div className="block">
            {/* <div className="container"> */}
            <div className="block-header">
               <Link title="Thiết kế thi công nội thất" href="/quy-trinh-ket-noi">
                  <h2 style={{ margin: 0, fontSize: "unset" }}>
                     <p className="block-header__title">Thiết kế thi công </p>
                  </h2>
               </Link>
               <p className="block-header__description">Xem ý tưởng, tính chi phí, nhận tư vấn và chọn nhà thầu phù hợp</p>
            </div>
            <ImageBySpaces keywordGroupsByType={props.keywordGroupsByType} />
            <DesignLibrary
               actualWorks={props.dataHomepage.actualWorks}
               designLibrary={props.dataHomepage.designLibraries}
               userBookmarks={props.dataHomepage.designLibraryUserBookmarks}
            />
            <Calculator />
            <Review />
            {/* <Procedure />
         <Partners partners={props?.dataHomepage?.partner} /> */}
         </div>
         {/* </div> */}
      </>
   );
}
