import React, { useEffect, useState } from "react";
import Link from "next/link";
import Slider from "react-slick";
import { Skeleton } from "@mui/material";

// Components
import { currency } from "../../../utils";
import { Paginate } from "../../../models/model";
import { ImageView } from "../../../components/image";
import Bookmark from "../../../components/modal/Bookmark";
import Product, { IProduct } from "../../../models/product.model";
// import BookmarkPost from "../../../components/bookmark/BookmarkPost";
import { EUserBookmarkTypes, IUserBookmark } from "../../../models/userBookmark.model";
import dynamic from "next/dynamic";
import CardProductV2 from "@Components/CardProductV2";

const BookmarkPost = dynamic(() => import("../../../components/bookmark/BookmarkPost"), {
   ssr: false,
   loading: () => <div style={{ height: "270px" }} />
});

interface IProps {
   userBookmarks: IUserBookmark[];
   products?: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks?: IUserBookmark[];
   };
   title?: string;
   handleChangeUserBookmark?: (action: "create" | "delete", userBookmark: IUserBookmark) => void;
}

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 6,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
   responsive: [
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 2.5,
            slidesToScroll: 3
         }
      }
   ]
};

export default function BestSeller({ ...props }: IProps) {
   const [userBookmarksState, setUserBookmarks] = useState<IUserBookmark[] | undefined>(props.userBookmarks);
   const [notification, setNotification] = useState<{ code: number; show: boolean }>();

   useEffect(() => {
      setUserBookmarks(props?.userBookmarks);
   }, [props?.userBookmarks]);

   const handleChangeNotification = (data: { code: number; show: boolean }) => {
      setNotification(data);
   };

   return (
      <section className="best-seller">
         <div className="block-sub d-flex justify-content-between align-items-center">
            <Link title={props.title === "propose" ? "Gợi ý cho bạn" : "Sản phẩm bán chạy"} href="/shop" >
               <h2 className="block-sub-title">
                  {props.title === "propose" ? "Gợi ý cho bạn" : "Sản phẩm bán chạy"}
               </h2>
            </Link>
            <Link title="Shop" href="/shop" className="link-more link">
               Xem tất cả{" "}
               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path
                     d="M3.99967 10.3754C4.0992 10.3759 4.19473 10.3362 4.26467 10.2654L8.26467 6.26541C8.4109 6.119 8.4109 5.88182 8.26467 5.73541L4.26467 1.73541C4.11692 1.59773 3.88667 1.60179 3.74386 1.7446C3.60105 1.88741 3.59699 2.11766 3.73467 2.26541L7.46967 6.00041L3.73467 9.73541C3.58844 9.88182 3.58844 10.119 3.73467 10.2654C3.80461 10.3362 3.90014 10.3759 3.99967 10.3754Z"
                     fill="#697F90"
                  />
               </svg>
            </Link>
         </div>
         {!props?.products ? (
            <div className="product-list">
               <div className="row gx-3">
                  {[...Array(4)].map((e, i) => (
                     <div className="col" key={i}>
                        <Skeleton animation="wave" width="100%" height={170} />
                     </div>
                  ))}
               </div>
            </div>
         ) : (
            <Slider {...settingsSlider} className="product-slider">
               {props?.products?.paginate?.docs?.map((product, i: number) => {
                  const bookmark = userBookmarksState?.find(bookmark => bookmark?.info?.openId === product._id);

                  return (
                     <div className="slider-item" key={product._id}>
                        <CardProductV2
                           showDes
                           fixedHeight
                           product={product}
                           bookmark={bookmark}
                           onChangeNotification={noti => handleChangeNotification(noti)}
                        />
                     </div>
                  );
               })}
            </Slider>
         )}
         <Bookmark show={notification?.show} notification={notification?.code} handleClose={() => handleChangeNotification({ code: 0, show: false })} />
      </section>
   );
}
